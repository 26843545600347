import React, { Component } from "react";
import "moment/locale/id.js";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import { DatePickerInput } from "rc-datepicker";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import { isEmpty, map } from "lodash";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter,
  MDBBtn,
} from "mdbreact";
import Select from "react-select";

const showSecond = false;
const str = showSecond ? "HH:mm:ss" : "HH:mm";

const moment = require("moment");

class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      student_id: "",
      is_show: false,
      isLoading: false,
      listField: [],
      fields: {},
      isOpen: false,
      isSubOpen: false,
      note_verification: "",
      tanggal_wawancara: "",
      tempat_wawancara: "", schedule_interview_at: '', schedule_interview_at_clock: '12:00', schedule_interview_place: '',
      schedule_socialization_at: '', schedule_socialization_at_clock: '12:00', schedule_socialization_place: '',
      schedule_test_at: '', schedule_test_at_clock: '12:00', schedule_test_place: '',
      jam_wawancara: "12:00:00",
      listPdf: {},
      valueOptionWawancara: 0,
      nowDate: new Date(),
    };
    this.toggle = this.toggle.bind(this)
    this.onChangeWawancara = this.onChangeWawancara.bind(this)
  }

  getFiles(files) {
    this.setState({
      encrypt_64: files[0].base64,
      original_name: files[0].name,
    });
  }

  componentDidMount() {

    fetch(global.variable.LINK_API + 'auth/parameter',
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        //body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          this.setState({ CB_REGTYPE: resData.data.prmRegistrationType });
          localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
        }
      });

    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
      fields: JSON.parse(localStorage.getItem("STUDENT_DETAIL")),
      is_show: true,
      listField: JSON.parse(
        localStorage.getItem("DATA_PARAMETER")
      ).prm_ms_student,
      listPdf: JSON.parse(localStorage.getItem("DATA_PARAMETER"))
    });
  }


  onChange = (e) => {
    let fields = this.state.fields;
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    fields[e.target.name] = a;
    this.setState({ fields });
  };

  createSelectItems(item) {
    const pdf_table = item.pdf_table;
    const test = "." + pdf_table;
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER"))[pdf_table];

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id} >
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeDate = (jsDate, dateString) => {
    this.setState({ tanggal_wawancara: dateString });
  };

  onChangeWawancara(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeTime = (value) => {
    let val;
    if (value) {
      val = value.format(str) + ":00";
    } else {
      val = "00:00:00";
    }
    this.setState({ jam_wawancara: val });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleBack = () => {
    this.props.history.push("/verifikasi");
  };

  download = (param) => (e) => {
    e.preventDefault();
    window.open(
      // "/image/" + param,
      param,
      "_blank",
      "location=yes,height=600,width=600,scrollbars=yes,status=yes"
    );

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  };

  createDropdownData() {
    let items = [
      {
        value: 1,
        label: 'Sekarang',
      },
      {
        value: 2,
        label: 'Teragendakan',
      },
      {
        value: 3,
        label: 'Menunggu Jadwal Wawancara',
      },
    ];
    return <Select options={items} onChange={this.onDropdownSelected2.bind(this)} placeholder="- Pilih Jadwal -" />;
  }

  onDropdownSelected2(e) {
    this.setState({
      valueOptionWawancara: e.value,
    });
  }

  form1() {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Nama Siswa</label>
            <input
              className="form-control"
              type="Text"
              name="jalur"
              value={localStorage.getItem("DATA_JALUR")}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label htmlFor="exampleForm2">Jalur</label>
            <input
              className="form-control"
              type="Text"
              name="fullname"
              maxLength="100"
              value={this.state.registration_type.name}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">

            <label htmlFor="exampleForm2">Nama Lengkap</label>
            <input
              className="form-control"
              type="Text"
              name="fullname"
              maxLength="100"
              value={this.state.fullname}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Nama Panggilan</label>
            <input
              className="form-control"
              type="Text"
              name="nickname"
              maxLength="100"
              value={this.state.nickname}
              disabled
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Jenis Kelamin</label>
            <input
              className="form-control"
              type="Text"
              name="gender"
              maxLength="100"
              value={this.state.gender.name}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>NIK</label>
            <input
              className="form-control"
              type="Text"
              name="nik"
              maxLength="50"
              value={this.state.nik}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>NO KK (Nomor Induk Keluarga)</label>
            <input
              className="form-control"
              type="Text"
              name="no_kk"
              value={this.state.no_kk}
              maxLength="25"
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>NISN</label>
            <input
              className="form-control"
              type="Text"
              name="nisn"
              maxLength="50"
              value={this.state.nisn}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Tempat Lahir</label>
            <input
              className="form-control"
              type="Text"
              name="pob"
              maxLength="100"
              value={this.state.pob}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Tanggal Lahir</label>
            <input
              className="form-control"
              type="Text"
              name="birth_certificate"
              maxLength="50"
              value={moment(this.state.dob, "YYYY-MM-DD").format("DD-MM-YYYY")}
              disabled
            />
          </div>
        </div>

        {/* <Form.Group controlId="exampleForm.ControlInput1">
            <label>No Akta Kelahiran</label>
             <input
            className="form-control"
              type="Text"
              name="birth_certificate"
              maxLength="50"
              value={this.state.birth_certificate}
              disabled />
          </Form.Group> */}
      </div>
    );
  }

  form2() {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Agama</label>
            <input
              className="form-control"
              type="Text"
              name="religion"
              maxLength="200"
              value={this.state.religion.name}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Kewarganegaraan</label>
            <input
              className="form-control"
              type="Text"
              name="citizen"
              maxLength="200"
              value={this.state.citizen.name}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Alamat</label>
            <input
              className="form-control"
              type="Text"
              name="address"
              maxLength="200"
              value={this.state.address}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <MDBRow>
              <MDBCol>
                <label>RT</label>
                <input
                  className="form-control"
                  type="Text"
                  name="rt"
                  maxLength="5"
                  value={this.state.rt}
                  disabled
                />
              </MDBCol>
              <MDBCol>

                <label>RW</label>
                <input
                  className="form-control"
                  type="Text"
                  name="rw"
                  maxLength="5"
                  value={this.state.rw}
                  disabled
                />
              </MDBCol>
            </MDBRow>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Kelurahan</label>
            <input
              className="form-control"
              type="Text"
              name="sub_district"
              maxLength="100"
              value={this.state.sub_district}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Kecamatan</label>
            <input
              className="form-control"
              type="Text"
              name="district"
              maxLength="100"
              value={this.state.district}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Kabupaten/Kota</label>
            <input
              className="form-control"
              type="Text"
              name="sub_province"
              maxLength="100"
              value={this.state.sub_province}
              disabled
            />
          </div>

          <div className="form-group col-md-6">

            <label>Provinsi</label>
            <input
              className="form-control"
              type="Text"
              name="province"
              maxLength="100"
              value={this.state.province}
              disabled
            />
          </div>
        </div>

        {/* <Form.Group controlId="exampleForm.ControlInput1">
          <label>Kodepos</label>
           <input
            className="form-control"
            type="Text"
            name="zipcode"
            maxLength="5"
            value={this.state.zipcode}
            disabled  />
        </Form.Group> */}
        {/* <Form.Group controlId="exampleForm.ControlInput1">
          <label>Garis Bujur</label>
           <input
            className="form-control"
            type="Text"
            name="longitude"
            maxLength="5"
            value={this.state.longitude}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <label>Garis Lintang</label>
           <input
            className="form-control"
            type="Text"
            name="latitude"
            maxLength="5"
            value={this.state.latitude}
            disabled  />
        </Form.Group> */}
      </div>
    );
  }

  form3() {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Tinggal Dengan</label>
            <input
              className="form-control"
              type="Text"
              name="stay_id"
              maxLength="2"
              value={this.state.stay.name}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Moda Transportasi</label>
            <input
              className="form-control"
              type="Text"
              name="transportation"
              maxLength="2"
              value={this.state.transportation.name}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Jarak Tempuh (KM)</label>
            <input
              className="form-control"
              type="Text"
              name="school_distance_hour"
              maxLength="5"
              value={this.state.school_distance_hour}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Waktu Tempuh (Menit)</label>
            <input
              className="form-control"
              type="Text"
              name="school_distance_minute"
              maxLength="5"
              value={this.state.school_distance_minute}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>No. Handphone</label>
            <input
              className="form-control"
              type="Text"
              name="hp"
              maxLength="2"
              value={this.state.hp}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Email</label>
            <input
              className="form-control"
              type="Text"
              name="email"
              maxLength="2"
              value={this.state.email}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Anak ke</label>
            <input
              className="form-control"
              type="Text"
              name="child"
              maxLength="2"
              value={this.state.child}
              disabled
            />
          </div>

          <div className="form-group col-md-6">

            <label>Dari bersaudara</label>
            <input
              className="form-control"
              type="Text"
              name="total_children"
              maxLength="2"
              value={this.state.total_children}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>
              Saudara Yang Masih Menjadi Tanggungan Orang Tua ... Orang
            </label>
            <input
              className="form-control"
              type="Text"
              name="sibling_dependent"
              maxLength="2"
              value={this.state.sibling_dependent}
              disabled
            />
          </div>

          {/* <Form.Group controlId="exampleForm.ControlSelect1">
          <label>Golongan Darah</label>
           <input
            className="form-control"
            type="Text"
            name="blood_type"
            maxLength="2"
            value={this.state.blood_type.name}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <label>Tinggi (cm)</label>
           <input
            className="form-control"
            type="Text"
            name="height"
            maxLength="5"
            value={this.state.height}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <label>Berat (kg)</label>
           <input
            className="form-control"
            type="Text"
            name="weight"
            maxLength="5"
            value={this.state.weight}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <label>Lingkar Kepala (cm)</label>
           <input
            className="form-control"
            type="Text"
            name="head_length"
            maxLength="5"
            value={this.state.head_length}
            disabled  />
        </Form.Group> */}
          <div className="form-group col-md-6">
            <label>Sekolah Asal (Sesuai Identitas Raport)</label>
            <input
              className="form-control"
              type="Text"
              name="school_from"
              maxLength="2"
              value={this.state.school_from.name}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Nama Sekolah Asal (Sesuai Identitas Raport)</label>
            <input
              className="form-control"
              type="Text"
              name="school_from_name"
              maxLength="2"
              value={this.state.school_from_name}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Alamat Sekolah Asal (Sesuai Identitas Raport)</label>
            <input
              className="form-control"
              type="Text"
              name="school_from_address"
              maxLength="2"
              value={this.state.school_from_address}
              disabled
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Lama Belajar (Dalam Tahun)</label>
            <input
              className="form-control"
              type="Text"
              name="study_year"
              maxLength="2"
              value={this.state.study_year}
              disabled
            />
          </div>
          <div className="form-group col-md-6">

            <label>Uang SPP Sekolah Asal</label>
            <CurrencyFormat
              value={this.state.spp}
              style={{ width: "100%" }}
              thousandSeparator={true}
              prefix={"Rp. "}
              disabled
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Daya Listrik</label>
            <input
              className="form-control"
              type="Text"
              name="electrical_power_id"
              maxLength="2"
              value={
                this.state.electrical_power
                  ? this.state.electrical_power.name
                  : ""
              }
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Biaya rata-rata listrik per bulan</label>

            <CurrencyFormat
              value={this.state.bill_pln}
              style={{ width: "100%" }}
              thousandSeparator={true}
              prefix={"Rp. "}
              disabled
            />
          </div>
        </div>

        <div>
          <div className="form-row">
            <div className="form-group col-md-6">

              <label>Keadaan Orang Tua</label>
              <input
                className="form-control"
                type="Text"
                name="parent_status_id"
                maxLength="2"
                value={
                  this.state.parent_status ? this.state.parent_status.name : ""
                }
                disabled
              />
            </div>
            <div className="form-group col-md-6">
              <label>Keadaan Pekerjaan Orang Tua</label>
              <input
                className="form-control"
                type="Text"
                name="both_parent_work"
                maxLength="2"
                value={
                  this.state.both_parent_work
                    ? this.state.both_parent_work.name
                    : ""
                }
                disabled
              />
            </div>
          </div>
        </div>
      </div >
    );
  }

  getMaster(id = 0, table) {
    var array = this.state.listPdf[table];
    var arrayfilter = array.filter((data) => { if (data.id == id) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '-';
    return name;
  }

  form4() {
    let guardian = this.state.fields.guardian;
    let showitem = [];
    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].nik}>
          <hr />
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Orang Tua{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].guardian_id, 'pdf_guardian')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Nama Lengkap{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].fullname}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              NIK{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].nik}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Tanggal Lahir{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {moment(guardian[i].dob, "YYYY-MM-DD").format("DD-MM-YYYY")}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Agama{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].religion_guardian_id, 'pdf_religion')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Status Hubungan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].status_guardian_id !== null ? this.getMaster(guardian[i].status_guardian_id, 'pdf_status_guardian') : guardian[i].status_guardian}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Pendidikan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].education_id, 'pdf_education_guardian')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Pekerjaan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].occupation_id, 'pdf_occupation')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Penghasilan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              <CurrencyFormat
                value={guardian[i].salary}
                displayType={"text"}
                style={{ width: "100%" }}
                thousandSeparator={true}
                prefix={"Rp. "}
                disabled
              />{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              No HP{" "}
            </label>
            <label className="col-md-3 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].hp}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Alamat{" "}
            </label>
            <label className="col-md-3 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].address}{" "}
            </label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form5() {
    let guardian = this.state.fields.sibling;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].name}>
          <hr />
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Nama{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Kelas{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].class}{" "}
            </label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form6() {
    let guardian = this.state.fields.achieve;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].name}>
          <hr />
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Jenis{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].achieve_type_id, 'pdf_achieve_type')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Nama Prestasi{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Penyelenggara{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].promoter}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Tingkat{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].achieve_rank_id, 'pdf_achieve_rank')}{" "}
            </label>
          </div>
          <div className="rowHorizontal">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Peringkat{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].rank}{" "}
            </label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form7() {
    //let guardian = this.state.scores;
    //let showitem = [];
    /*guardian.map((item) =>{
      let subject = item.subject;
        showitem.push(
          <div key={item.classroom_id}>
            <label className="col-md-12" style={{width: 200, margin:5, fontSize: 20}} >{item.classroom_name} </label>,
            <hr/>
            {subject.map((item1) =>{
              /*return(
                <Form.Group controlId="exampleForm.ControlInput1" key=''>
                <div className='rowHorizontal'>
                  <label className="col-md-3" style={{width: 200, margin:5}}>{item1.subject_name} </label>
                  <label className="col-md-3" style={{width: 200, margin:5}}>{item1.semester[0].score} </label>
                  <label className="col-md-3" style={{width: 200, margin:5}}>{item1.semester[1].score} </label>
                </div>
                </Form.Group>
              );*/
    /*})}
          </div>
        );
       })*/
    /*(guardian.map((item) =>{
        let subject = item.subject;
          return(
            <div key={item.classroom_id}>
              <label className="col-md-12" style={{width: 200, margin:5, fontSize: 20}} >{item.classroom_name} </label>,
              <hr/>
              {subject.map((item1) =>{
                /*return(
                  <Form.Group controlId="exampleForm.ControlInput1" key={item.classroom.id+"_"+item1.id}>
                  <div className='rowHorizontal'>
                    <label className="col-md-3" style={{width: 200, margin:5}}>{item1.subject.name} </label>
                     <input
            className="form-control"className="col-md-3"
                      type="Text"
                      name={item.classroom.id+"_"+item1.subject.id+"_1"}
                      maxLength="3"
                      onChange={this.onChange}
                      style={{width: 100, margin:5}}
                      placeholder="Semester 1" />
                     <input
            className="form-control"className="col-md-3"
                      type="Text"
                      name={item.classroom.id+"_"+item1.subject.id+"_2"}
                      maxLength="3"
                      onChange={this.onChange}
                      style={{width: 100, margin:5}}
                      placeholder="Semester 2" />
                  </div>
                  </Form.Group>
                );*/
    /*})}
            </div>
          );
         })*/
    //return showitem;
  }

  formdokumen() {
    let guardian = this.state.fields.document_upload;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={i}>
          <br></br>

          <label className="font-weight-bold">{this.getMaster(guardian[i].document_id, 'prm_document_upload')}</label>
          <div>
            {/*<Image alt="Dokumen" style={{width:400}} src={this.state.upload_documents[i].encrypt_64} thumbnail />*/}

            <Link
              to=""
              target="_blank"
              style={{ marginTop: 10 }}
              onClick={this.download(guardian[i].link)}
            >
              Lihat Dokumen
            </Link>
          </div>
        </div>
      );
    }

    // showitem.push(
    //   <div>
    //     <hr />
    //     <label style={{ fontSize: 20, textDecorationLine: "underline" }}>
    //       Catatan Dokumen
    //     </label>
    //     <br></br>

    //     <label htmlFor="exampleForm2">Catatan</label>
    //     <textarea
    //       className="form-control"
    //       as="textarea"
    //       rows="5"
    //       name="note_document_upload"
    //       maxLength="100"
    //       value={this.state.note_document_upload}
    //       disabled
    //     />
    //   </div>
    // );
    return showitem;
  }

  formbukti() {
    return (
      /*<Image alt="Bukti transfer" style={{ width: 400 }} src={this.state.upload_transaction.encrypt_64} thumbnail />*/
      this.state.upload_transaction ? (
        <div>
          <Link
            target="_blank"
            onClick={this.download(this.state.upload_transaction.id)}
          >
            Lihat Bukti Bayar
          </Link>


          <label htmlFor="exampleForm2">Catatan</label>
          <textarea
            className="form-control"
            type="Text"
            name="note"
            maxLength="50"
            value={this.state.note}
            onChange={this.onChange}
            disabled
          />
        </div>
      ) : (
        ""
      )
    );
  }

  handleTest = (event) => {
    global
      .swal({
        text: "Apakah anda yakin akan disubmit?",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        // const data = {
        //   student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
        //   flow_id: 4,
        //   note_verification: this.state.note_verification,
        // };
        const data = [];
        let items;
        items = Object.create({});
        items.field = "note_verification";
        items.value = confirm;
        data.push(items);
        const form_data = new FormData();
        form_data.append("registration_id", this.state.student_id);
        form_data.append("action", "back");
        form_data.append("data", JSON.stringify(data));

        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: form_data,
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          let m = moment();
          m = moment(resData.data.schedule_test_at, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          );
          global
            .swal(
              "Success",
              "Jadwal test adalah " +
              m +
              "\n Pada pukul 13.00 – 15.00 WIB \nDi ruang " +
              resData.data.classroom_id,
              "success"
            )
            .then((value) => {
              this.props.history.push("/verifikasi");
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleCadangan = (event) => {
    global
      .swal({
        text: "Apakah anda yakin akan disubmit?",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 5,
          is_reserve: "1",
          note_verification: this.state.note_verification,
        };

        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          const moment = require("moment");
          let m = moment();
          m = moment(
            resData.data.schedule_interview_at,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD-MM-YYYY HH:mm:ss");
          global
            .swal("Success", "Jadwal Wawancara Cadangan adalah " + m, "success")
            .then((value) => {
              this.props.history.push("/verifikasi");
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  validateInputTest = async () => {
    let ready = true;
    if (parseInt(this.state.listPdf.prm_unit.schedule_test_at) === 1 && parseInt(this.state.listPdf.prm_unit.schedule_test_place) === 1) {
      if (this.state.schedule_test_at !== '') {
        if (this.state.schedule_test_at_clock === '' || this.state.schedule_test_place === '') {
          ready = false;
        }
      }
    }
    return ready;
  }

  handleWawancara = async (event) => {
    const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');
    if (this.state.valueOptionWawancara === 2) {
      if (this.state.listPdf.prm_unit.schedule_interview_at && this.state.schedule_interview_at === "") {
        global.swal("Error", "Harap isi tanggal wawancara.", "info");
        return;
      } else if (this.state.listPdf.prm_unit.schedule_interview_at && this.state.schedule_interview_at_clock === "") {
        global.swal("Error", "Harap isi jam wawancara.", "info");
        return;
      } else if (this.state.listPdf.prm_unit.schedule_interview_place && this.state.schedule_interview_place === "") {
        global.swal("Error", "Harap isi tempat/ link wawancara.", "info");
        return;
      }
    }
    this.setState({ isLoading: true });
    const data = [];
    let items;
    items = Object.create({});
    items.field = 'note_verification';
    items.value = this.state.note_verification;
    data.push(items);
    const form_data = new FormData();
    form_data.append("registration_id", this.state.student_id);
    form_data.append("action", "next")
    form_data.append('data', JSON.stringify(data));
    if (parseInt(this.state.listPdf.prm_unit.schedule_interview_at) === 1 && parseInt(this.state.listPdf.prm_unit.schedule_interview_place) === 1) {
      if (this.state.valueOptionWawancara === 0) {
        this.setState({ isLoading: false });
        global.swal("Info", 'Jadwal Wawancara wajib diisi', "info");
        global.swal.stopLoading();
        global.swal.close();
        return;
      }
      if (this.state.valueOptionWawancara === 2) {
        if (this.state.schedule_interview_at === '' || this.state.schedule_interview_at_clock === '' || this.state.schedule_interview_place === '') {
          this.setState({ isLoading: false });
          global.swal("Info", 'Data Wawancara Belum Lengkap', "info");
          global.swal.stopLoading();
          global.swal.close();
          return;
        }
        var times = this.state.schedule_interview_at + ' ' + this.state.schedule_interview_at_clock + ':00';
        form_data.append('schedule_interview_at', times)
        form_data.append('schedule_interview_place', this.state.schedule_interview_place)
      } else if (this.state.valueOptionWawancara === 1) {
        form_data.append('schedule_interview_at', formattedDate);
        form_data.append('schedule_interview_place', 'Wawancara Langsung');
      } else {
        form_data.append('schedule_interview_at', '');
        form_data.append('schedule_interview_place', '');
      }
    }
    if (await this.validateInputTest()) {
      if (this.state.schedule_test_at !== '') {
        form_data.append("schedule_test_at", this.state.schedule_test_at + ' ' + this.state.schedule_test_at_clock + ':00');
        form_data.append("schedule_test_place", this.state.schedule_test_place);
      }
    } else {
      this.setState({ isLoading: false });
      global.swal("Info", 'Data Tes Belum Lengkap', "info");
      global.swal.stopLoading();
      global.swal.close();
      return;
    }
    // this.setState({ isLoading: false });
    // for (var pair of form_data.entries()) {
    //   console.log(pair[0], pair[1]);
    // }
    // return;
    return fetch(global.variable.LINK_API + "flow/update", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: form_data,
    })
      .then(this.handleErrors)
      .then((response) => response.json())
      .then((resData) => {
        this.setState({ isLoading: false });
        if (resData.message === "Success") {
          global
            .swal("Success", "Berhasil verifikasi data.", "success")
            .then((value) => {
              if (this.state.flow_id === 11) {
                window.location.href = "/verifikasioffline";
              } else {
                this.props.history.push("/verifikasi");
              }
            });
        } else {
          global.swal("Info", resData.message, "info");
          global.swal.stopLoading();
          global.swal.close();
          return;
        }
      });
    // .catch((err) => {
    //   this.setState({ isLoading: false });
    //   if (err) {
    //     if (err.code !== 403) {
    //       let message;
    //       if ('errors' in err) {
    //         if (Array.isArray(err.errors)) {
    //           message = err.errors[0];
    //         } else {
    //           message = err.errors;
    //         }
    //       } else {
    //         message = "Koneksi ke server gagal.";
    //       }
    //       global.swal("Error", message, "info");
    //     }
    //   } else {
    //     global.swal.stopLoading();
    //     global.swal.close();
    //   }
    // });
  };

  failValidation = (val) => {
    this.setState({ isLoading: false });
    global.swal("Info", val, "info");
    global.swal.stopLoading();
    global.swal.close();
    return;
  }

  handleTolak = (event) => {
    global
      .swal({
        text: "Alasan ditolak?",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = [];
        let items;
        items = Object.create({});
        items.field = "note_reject";
        items.value = confirm;
        data.push(items);
        const form_data = new FormData();
        form_data.append("registration_id", this.state.student_id);
        form_data.append("action", "reject");
        form_data.append("data", JSON.stringify(data));
        // for (let [key, value] of form_data.entries()) {
        //   console.log(`${key}: ${value}`);
        // }
        // global.swal.stopLoading();
        // return;
        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: form_data,
        });
      })
      .then(this.handleErrors)
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global.swal("Success", "Berhasil Tolak", "success").then((value) => {
            this.props.history.push("/verifikasi");
          });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            let message;
            if ('errors' in err) {
              if (Array.isArray(err.errors)) {
                message = err.errors[0];
              } else {
                message = err.errors;
              }
            } else {
              message = "Koneksi ke server gagal.";
            }
            global.swal("Error", message, "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleJP = (event) => {
    global
      .swal({
        text: "Alasan Pindah Jalur",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;

        let note_confirmation =
          "Apakah anda yakin anak anda akan dipindah ke Jalur Prestasi?";
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 3,
          registration_type_id: "JP",
          note_change_registration_type: confirm,
          note_confirmation: note_confirmation,
        };
        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global
            .swal("Success", "Berhasil Ubah Jalur", "success")
            .then((value) => {
              this.props.history.push("/verifikasi");
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleJPL = (event) => {
    global
      .swal({
        text: "Alasan Pindah Jalur",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;

        let note_confirmation =
          "Apakah anda yakin anak anda akan dipindah ke Jalur Pangudi Luhur?";
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 3,
          registration_type_id: "JPL",
          note_change_registration_type: confirm,
          note_confirmation: note_confirmation,
        };
        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global
            .swal("Success", "Berhasil Ubah Jalur", "success")
            .then((value) => {
              this.props.history.push("/verifikasi");
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleReturn = (event) => {
    global
      .swal({
        text: "Alasan dokumen dikembalikan",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        // const data = {
        //   student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
        //   note_return: confirm,
        // };
        const data = [];
        let items;
        items = Object.create({});
        items.field = "note_return";
        items.value = confirm;
        data.push(items);
        const form_data = new FormData();
        form_data.append("registration_id", this.state.student_id);
        form_data.append("action", "back_input");
        form_data.append("data", JSON.stringify(data));
        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: form_data,
        });
      })
      .then(this.handleErrors)
      .then((response) => (response.json()))
      .then((resData) => {
        if (resData.message === "Success") {
          global
            .swal("Success", "Berhasil Dikembalikan", "success")
            .then((value) => {
              this.props.history.push("/verifikasi");
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            let message;
            if ('errors' in err) {
              if (Array.isArray(err.errors)) {
                message = err.errors[0];
              } else {
                message = err.errors;
              }
            } else {
              message = "Koneksi ke server gagal.";
            }
            global.swal("Error", message, "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleErrors = async (response) => {
    this.setState({ isLoading: false });
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors }
      throw errorMessage;
    }
    return response;
  }

  handleJU = (event) => {
    global
      .swal({
        text: "Alasan Pindah Jalur",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;

        let note_confirmation =
          "Apakah anda yakin anak anda akan dipindah ke Jalur Umum?";
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 3,
          registration_type_id: "JU",
          note_change_registration_type: confirm,
          note_confirmation: note_confirmation,
        };

        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global
            .swal("Success", "Berhasil Ubah Jalur", "success")
            .then((value) => {
              this.props.history.push("/verifikasi");
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  modal() {
    return (
      <MDBModal isOpen={this.state.isOpen} toggle={this.toggle}>
        <form>
          <MDBModalHeader toggle={this.toggle}>Konfirmasi Wawancara</MDBModalHeader>
          <MDBModalBody>
            {this.state.listPdf.prm_unit.schedule_test_at ? (
              <div className="rowHorizontal mb-2">
                <label>Tanggal Tes</label>
                <input className="form-control" type="date" onChange={this.onChangeWawancara} value={this.state.schedule_test_at} name="schedule_test_at" required />
              </div>
            ) : ''}
            {this.state.listPdf.prm_unit.schedule_test_at ? (
              <div className="rowHorizontal mb-2">
                <label>Jam Tes</label>
                <input className="form-control" type="time" onChange={this.onChangeWawancara} name="schedule_test_at_clock" value={this.state.schedule_test_at_clock} required />
              </div>
            ) : ''}
            {this.state.listPdf.prm_unit.schedule_test_place ? (
              <div className="rowHorizontal mb-2">
                <label>Tempat Tes</label>
                <input className="form-control" type="text" onChange={this.onChangeWawancara} name="schedule_test_place" value={this.state.schedule_test_place} required />
              </div>
            ) : ''}
            <div className="form-group">
              <label>Wawancara Dilakukan <span className="text-danger"> *</span></label>
              {this.createDropdownData()}
            </div>
            {this.state.valueOptionWawancara === 2 ? this.state.listPdf.prm_unit.schedule_interview_at ? (
              <div className="rowHorizontal mb-2">
                <label>Tanggal Wawancara</label>
                <input className="form-control" type="date" onChange={this.onChangeWawancara} value={this.state.schedule_interview_at} name="schedule_interview_at" required />
              </div>
            ) : '' : ''}
            {this.state.valueOptionWawancara === 2 ? this.state.listPdf.prm_unit.schedule_interview_at ? (
              <div className="rowHorizontal mb-2">
                <label>Jam Wawancara</label>
                <input className="form-control" type="time" onChange={this.onChangeWawancara} name="schedule_interview_at_clock" value={this.state.schedule_interview_at_clock} required />
              </div>
            ) : '' : ''}
            {this.state.valueOptionWawancara === 2 ? this.state.listPdf.prm_unit.schedule_interview_place ? (
              <div className="rowHorizontal mb-2">
                <label>Tempat Wawancara</label>
                <input className="form-control" type="text" onChange={this.onChangeWawancara} name="schedule_interview_place" value={this.state.schedule_interview_place} required />
              </div>
            ) : '' : ''}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="yellow" onClick={this.toggle}>Kembali</MDBBtn>
            <MDBBtn color="primary" disabled={this.state.isLoading} onClick={this.handleWawancara}>
              {
                this.state.isLoading ? (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : 'Lanjut'
              }
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    )
  }

  render() {
    let name_modal =
      this.state.registration_type_id === "JU" &&
        (this.state.flow_id === 3 || this.state.flow_id === 11)
        ? "Test"
        : "Wawancara";
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Verifikasi
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          {map(this.state.listField, (item, idx) => {
                            const field = item.field;
                            return (
                              <React.Fragment key={idx}>
                                <div className="form-group col-md-12">
                                  <label>
                                    {item.name}
                                    {/* {item.is_required == 1 ? (<span className="text-danger"> *</span>) : ""} */}
                                  </label>
                                  {item.type == 1 ? (
                                    <>
                                      {item.type_text == "nominal" ? (
                                        <CurrencyFormat
                                          className="form-control"
                                          input="number"
                                          name={item.field}
                                          onChange={this.onChangeNom}
                                          value={this.state.fields[item.field] || ''}
                                          maxLength="25"
                                          thousandSeparator={true}
                                          disabled
                                          style={{ width: "100%" }}
                                        />
                                      ) : (
                                        <input
                                          className="form-control"
                                          type={item.type_text}
                                          name={item.field}
                                          maxLength="100"
                                          value={this.state.fields[item.field] || ''}
                                          onChange={this.onChange}
                                          disabled
                                          required={
                                            item.is_required == 1
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <select
                                        id={idx}
                                        className="form-control"
                                        name={item.field}
                                        disabled
                                        onChange={this.onDropdownSelected.bind(
                                          this
                                        )}
                                        value={this.state.fields[item.field] || ''}
                                      >
                                        <option value="">
                                          - Pilih {item.name} -
                                        </option>
                                        {this.createSelectItems(item)}
                                      </select>
                                    </>
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                        {/* <label>Nama Siswa</label>
                          <input
                            className="form-control"
                            type="Text"
                            name="jalur"
                            value={localStorage.getItem("DATA_JALUR")}
                            disabled
                          /> */}
                        {/* {this.state.is_show ? this.form1() : ""}

                        {this.state.is_show ? this.form2() : ""}

                        {this.state.is_show ? this.form3() : ""} */}
                        {
                          this.state.is_show ? (
                            <div>
                              <hr />
                              <label
                                className="font-weight-bold"
                                style={{
                                  fontSize: 20,
                                  textDecorationLine: "underline",
                                }}
                              >
                                Data Orang Tua
                              </label>
                            </div>
                          ) : ""
                        }
                        {this.state.is_show && this.state.fields.guardian.length > 0 ? this.form4() : ""}

                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Saudara yang masih bersekolah di{" "}
                              {this.state.nama_sekolah}
                            </label>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.is_show && this.state.fields.sibling.length > 0 ? this.form5() : ""}

                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Prestasi
                            </label>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.is_show && this.state.fields.achieve.length > 0 ? this.form6() : ""}
                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Dokumen
                            </label>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.is_show ? this.formdokumen() : ""}

                        {this.state.is_show && this.state.fields.document_upload.length > 0 ? (
                          <>
                            <hr />
                            <label htmlFor="exampleForm2" className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}>Catatan Dokumen</label>
                            <textarea
                              className="form-control"
                              type="textarea"
                              rows="5"
                              name="note_document_upload"
                              maxLength="500"
                              disabled
                              defaultValue={this.state.fields.note_document_upload || ''}
                              onChange={this.onChange}
                            />
                          </>
                        ) : null}

                        <div>
                          <hr />
                          <label
                            style={{
                              fontSize: 20,
                              fontWeight: 'bold',
                              textDecorationLine: "underline",
                            }}
                          >
                            Catatan Verifikasi
                          </label>
                        </div>

                        <label>Catatan</label>
                        <textarea
                          className="form-control"
                          as="textarea"
                          rows="5"
                          name="note_verification"
                          value={this.state.note_verification}
                          onChange={this.onChangeWawancara}
                        />
                        {this.state.flow_id === 3 &&
                          this.state.registration_type_id !== "JU" ? (
                          <div className="rowHorizontal">
                            {this.state.registration_type_id === "JR" ? (
                              <div className="rowHorizontal">
                                <MDBBtn
                                  onClick={this.handleJP}
                                  block
                                  style={{ margin: 5, height: 50 }}
                                >
                                  Ubah ke Jalur Prestasi
                                </MDBBtn>
                                <MDBBtn
                                  onClick={this.handleJPL}
                                  block
                                  style={{ margin: 5, height: 50 }}
                                >
                                  Ubah ke Jalur Pangudi Luhur
                                </MDBBtn>
                                <MDBBtn
                                  onClick={this.handleJU}
                                  block
                                  style={{ margin: 5, height: 50 }}
                                >
                                  Ubah ke Jalur Umum
                                </MDBBtn>
                              </div>
                            ) : this.state.registration_type_id === "JP" ? (
                              <div className="rowHorizontal">
                                <MDBBtn
                                  onClick={this.handleJPL}
                                  block
                                  style={{ margin: 5, height: 50 }}
                                >
                                  Ubah ke Jalur Pangudi Luhur
                                </MDBBtn>
                                <MDBBtn
                                  onClick={this.handleJU}
                                  block
                                  style={{ margin: 5, height: 50 }}
                                >
                                  Ubah ke Jalur Umum
                                </MDBBtn>
                              </div>
                            ) : (
                              <div className="rowHorizontal">
                                <MDBBtn
                                  onClick={this.handleJU}
                                  block
                                  style={{ margin: 5, height: 50 }}
                                >
                                  Ubah ke Jalur Umum
                                </MDBBtn>
                              </div>
                            )}
                          </div>
                        ) : null}
                        <br></br>
                        <div className="row">
                          <div className="col-md-4 col-12">
                            <MDBBtn
                              onClick={this.handleTolak}
                              block
                              color='danger'
                              style={{
                                margin: 5,
                                height: 50,
                              }}
                            >
                              Tolak
                            </MDBBtn>
                          </div>
                          <div className="col-md-4 col-12">
                            <MDBBtn color='warning' onClick={this.handleReturn} block
                              style={{ margin: 5, height: 50, }}
                            >
                              Kembalikan
                            </MDBBtn>
                          </div>

                          <div className="col-md-4 col-12">
                            <MDBBtn
                              color="success"
                              data-toggle="modal"
                              data-target="#modal"
                              onClick={this.toggle}
                              block
                              style={{
                                margin: 5,
                                height: 50,
                                // backgroundColor: "#33cc33",
                                padding: "10px",
                              }}
                            >
                              Konfirmasi Wawancara
                            </MDBBtn>
                          </div>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
                {this.state.is_show ? this.modal() : ''}
                {/* 
                <div
                  className="modal fade"
                  id="modal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="ModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h3 className="modal-title" id="ModalLabel">
                          Masukan Jadwal {name_modal}
                        </h3>

                        <label>Nama Siswa</label>
                        <input
                          className="form-control"
                          type="Text"
                          name="jalur"
                          value={localStorage.getItem("DATA_JALUR")}
                          disabled
                        />
                      </div>
                      <div className="modal-body">
                        <div style={{ display: "flex" }}>

                          <label>Tanggal {name_modal}</label>
                          <DatePickerInput
                            displayFormat="DD-MM-YYYY"
                            returnFormat="YYYY-MM-DD"
                            className="my-react-component"
                            value={this.state.tanggal_wawancara}
                            onChange={this.onChangeDate}
                            readOnly={true}
                            style={{ width: 200 }}
                          />
                          <form

                            style={{ marginLeft: 50 }}
                          />
                          <label>Jam {name_modal}</label>
                          <form>
                            <TimePicker
                              style={{ width: 100, marginTop: 3 }}
                              showSecond={showSecond}
                              // defaultValue={moment()}
                              value={moment(
                                this.state.jam_wawancara,
                                "hh:mm:ss"
                              )}
                              className="my-react-component"
                              onChange={this.onChangeTime}
                            />
                          </form>
                        </div>

                        <label>Tempat/ Link {name_modal}</label>
                        <input
                          className="form-control"
                          type="Text"
                          name="tempat_wawancara"
                          maxLength="200"
                          value={this.state.tempat_wawancara}
                          onChange={this.onChange}
                          required
                        />
                      </div>
                      <div className="modal-footer">
                        <MDBBtn
                          type="MDBBtn"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </MDBBtn>
                        <MDBBtn
                          type="MDBBtn"
                          className="btn btn-primary"
                          onClick={this.handleSubmit}
                        >
                          Submit
                        </MDBBtn>
                      </div>
                      
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
